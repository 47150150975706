import axios from 'axios';


export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {

        addCitizenReport(_, data) {
            return new Promise((resolve, reject) => {
                axios.post('citizen_report', data).then(resp => {
                    resolve(resp);
                }).catch(e => {
                    reject(e);
                })
            })
        },
        
        getGallery(_, _params) {
            return new Promise((resolve, reject) => {
                axios.get('/gallery', { params: _params }).then(resp => {

                    resolve(resp.data);
                }).catch(e => {
                    reject(e);
                })
            });
        },
    },
    getters: {

    }

}