<template>
  <div>
    <br /><br />
    <h2 class="mt-5 text-center text-primary"><b> Pengaduan Masyarakat </b></h2>
    <br /><br />
    <section>
      <form>
        <div class="row">
          <div
            class="
              col-md-10
              mx-auto
              offset-md-1
              my-5
              p-5
              rounded
              bg-light
              shadow-lg
            "
          >
            <div class="mb-3">
              <label for="nama-lengkap" class="form-label fw-bold"
                >Nama Lengkap <span class="text-danger">*</span></label
              >
              <input
                v-model="form.name"
                type="text"
                placeholder="masukkan nama"
                class="form-control"
                id="nama-lengkap"
              />
            </div>
            <div class="mb-3">
              <label for="alamat-lengkap" class="form-label fw-bold"
                >Alamat Lengkap <span class="text-danger">*</span></label
              >
              <input
                v-model="form.address"
                type="text"
                placeholder="masukkan alamat"
                class="form-control"
                id="alamat-lengkap"
              />
            </div>
            <div class="mb-3">
              <label for="no-telp" class="form-label fw-bold"
                >No. Telp <span class="text-danger">*</span></label
              >
              <input
                v-model="form.phone_number"
                type="text"
                placeholder="masukkan nomor telepon"
                class="form-control"
                id="no-telp"
              />
            </div>
            <div class="mb-3">
              <label for="isi-laporan" class="form-label fw-bold"
                >Isi Laporan Anda <span class="text-danger">*</span></label
              >
              <textarea
                v-model="form.report"
                class="form-control"
                id="isi-laporan"
                rows="5"
              ></textarea>
            </div>
            <div class="row">
              <div class="col">
                <div class="row mb-3">
                  <div
                    class="col-lg-3 mr-auto"
                    v-if="!preview"
                    @click="getDocs"
                  >
                    <label for="">Lampiran</label>
                    <div
                      style="
                        height: 150px;
                        border-color: #1d4374 !important ;
                        border-style: dashed !important;
                      "
                      class="
                        border
                        text-center
                        d-flex
                        flex-column
                        justify-content-center
                      "
                    >
                      <span
                        class="material-icons"
                        style="font-size: 40px; color: #1d4374"
                        >add_photo_alternate</span
                      >
                      <p class="text-main mt-1">
                        Upload Foto <br />
                        <span style="font-size: 9px">(maks: 2MB) </span>
                      </p>
                      <input
                        type="file"
                        accept=".png,.jpg"
                        id="docs"
                        @change="setDocs"
                        hidden
                      />
                    </div>
                    <div class="progress mt-3">
                      <div
                        class="
                          progress-bar
                          progress-bar-striped
                          progress-bar-animated
                        "
                        role="progressbar"
                        :style="
                          'width: ' +
                          this.progress +
                          '%; background-color: #1d4374;'
                        "
                        aria-valuenow="10"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        <p class="m-0 text-center" style="font-weight: bold">
                          {{ this.progress.toFixed(0) + " %" }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="preview"
                    class="
                      col-lg-3
                      mr-auto
                      d-flex
                      text-left
                      flex-column
                      justify-content-center
                    "
                  >
                    <label for="">Lampiran</label>
                    <vue-load-image>
                      <img
                        :src="preview"
                        style="
                          height: 150px;
                          width: 100%;
                          object-fit: cover !important;
                        "
                        slot="image"
                        alt=""
                      />
                      <img
                        slot="preloader"
                        height="50px"
                        width="50px"
                        src="@/assets/loading.svg"
                      />
                      <div slot="error">error message</div>
                    </vue-load-image>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-8 mx-auto text-center">
            <button
              v-if="
                form.name && form.address && form.phone_number && form.report
              "
              @click.prevent="addCitizenReport"
              class="btn btn-lg btn-primary text-uppercase fw-bold"
            >
              Kirim Laporan Pengaduan
            </button>
            <button
              v-else
              @click.prevent="reminder = true"
              class="btn btn-lg btn-primary text-uppercase fw-bold"
            >
              Kirim Laporan Pengaduan
            </button>
            <br />
            <span v-show="reminder == true" class="text-danger fst-italic"
              >Data Belum Lengkap!</span
            >
          </div>
        </div>
      </form>
    </section>
    <br /><br />
  </div>
</template>

<script>
import firebase from "firebase/app";
import VueLoadImage from "vue-load-image";
export default {
  components: {
    VueLoadImage,
    // AuthModal,
  },
  data() {
    return {
      reminder: false,
      form: {
        file: "",
      },
      progress: 0,
      preview: null,
    };
  },
  methods: {
    addCitizenReport() {
      if (confirm("Data akan ditambah. Apakah Anda yakin?")) {
        this.$store
          .dispatch("data/addCitizenReport", this.form)
          .then(() => {
            this.$toast.success("Berhasil menambahkan pengaduan!");
            this.$router.push("/");
          })
          .catch((e) => {
            this.$toast.error(e);
          });
      }
    },
    setDocs(event) {
      this.uploadFile(event.target.files[0]);
    },
    getDocs() {
      document.getElementById("docs").click();
    },
    uploadFile(file) {
      var storageRef = firebase
        .storage()
        .ref("docPengaduan/" + file.name)
        .put(file);
      // storageRef.put(this.file);
      storageRef.on(
        "state_changed",
        (snapshot) => {
          console.log(snapshot);
          this.progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
          alert("Permintaan Gagal Diproses!!\n\n" + error.message);
        },
        () => {
          this.progress = 100;
          storageRef.snapshot.ref.getDownloadURL().then((resp) => {
            this.preview = resp;
            this.form.file = this.preview;

            // console.log();
            // alert("Selamat! File Berhasil Diupload!!");
            this.progress = 0;
          });
        }
      );
    },
  },
};
</script>

<style scoped>
.text-primary {
  color: #0a468f !important;
}
.btn-primary {
  background: linear-gradient(205.11deg, #3c6ca7 15.96%, #5a98f2 97.98%);
  color: white;
}
.btn-primary:hover {
  box-shadow: 0px 4px 10px 0px #888;
}
p {
  font-size: 21px;
  line-height: 2;
}
hr {
  color: #0a468f;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 20px;
  width: 100px;
  height: 4px;
  opacity: 100%;
  border-radius: 100px;
}
body {
  width: 100% !important;
}
</style>