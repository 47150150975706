import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue';
import StrukturOrganisasi from '../views/StrukturOrganisasi.vue';
import Report from '../views/Report.vue';
import Register from '../views/Register.vue';
import Detail from '../components/Shared/Detail.vue';



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/gallery',
    name: 'Gallery',

    component: () => import(/* webpackChunkName: "about" */ '../views/Gallery.vue')
  },
  {
    path: '/gallery/detail/:data',
    name: 'Gallery Detail',
    props: true,

    component: () => import(/* webpackChunkName: "about" */ '../views/detail-gallery.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/struktur-organisasi',
    name: 'StrukturOrganisasi',
    component: StrukturOrganisasi,
  },
  {
    path: '/report',
    name: 'Report',
    component: Report,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/detail',
    name: 'detail',
    components: { default: Detail },
    props: true

  },
]

const router = new VueRouter({
  routes,
  mode: 'hash',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

export default router
