import "@babel/polyfill";
import "mutationobserver-shim";
import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap/dist/js/bootstrap.js";

import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-vue/dist/bootstrap-vue.js";

import "./plugins/bootstrap-vue";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import * as VueGoogleMaps from 'vue2-google-maps'
import store from '@/store';
import Axios from 'axios';
import VueLoading from 'vue-loading-template'
import 'material-icons/iconfont/material-icons.css';

// modal
import VModal from 'vue-js-modal/dist/ssr.nocss'

import 'vue-js-modal/dist/styles.css'

Vue.use(VModal, { })

// moment
const moment = require('moment')
require('moment/locale/id')

Vue.use(require('vue-moment'), {
    moment
})

// splide

import VueSplide from '@splidejs/vue-splide';
 
Vue.use( VueSplide );

// toast
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 3,
  newestOnTop: true,
  position: "top-center",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  // draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
});
// leaflet
import "leaflet/dist/leaflet.css";
import "leaflet-geosearch/dist/geosearch.css";


// Firebase
import firebase from "firebase/app";

import 'firebase/storage';
import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = 
{
  apiKey: "AIzaSyCwAACGH2QF99QhbQuv3xDxwJFRdxplgYg",
  // authDomain: "pju-file-f0da7.firebaseapp.com",
  projectId: "bwa-flutix-2246a",
  storageBucket: "bwa-flutix-2246a.appspot.com",
  messagingSenderId: "359270072999",
  appId: "1:359270072999:android:8462c6eb8c5d54a9975182",
  // measurementId: "G-GSPV0LJ3DX"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCile1Arb1ipCD0WM8cDkmYL-dhgR6XBK8',
  },
})

Vue.use(VueLoading, {

  type : "bubbles",
  color: "#036163",
  size: {height: "50px", width: "50px"}

});

Vue.config.productionTip = false;
Axios.defaults.baseURL = 'http://api-silaper.bappedapagaralam.org/api/';
Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
