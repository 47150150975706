<template>
  <div class="mobile">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      label-next=""
      label-prev=""
      background="#fff"
      img-width="1024"
      img-height="350"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <!-- Slides with image only -->
      <b-carousel-slide caption="Blank Image" img-blank>
        <div class="row">
          <div class="col-md-6 mt-3 text-center">
            <h2 class="mt-5 text-primary"><b> BAPPEDA </b></h2>
            <h4 class="text-primary"><b> KOTA PAGARALAM </b></h4>
            <p class="text-primary">
              Tercapainya Kualitas dan Akuntabilitas <br />Perencanaan
              Pembangunan Kota Pagaralam
            </p>
            <div class="row">
              <div class="col-md-6 mx-auto text-center d-grid">
                <router-link
                  class="btn btn-primary btn-block rounded-pill"
                  to="/profile"
                  >Selengkapnya</router-link
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <img src="@/assets/images/slide_1.png" style="width: 100%" alt="" />
          </div>
        </div>
      </b-carousel-slide>
      <b-carousel-slide caption="Blank Image" img-blank>
        <div class="row">
          <div class="col-md-6 mt-3 text-center">
            <h2 class="mt-5 text-primary"><b> SILAPER </b></h2>
            <h4 class="text-primary">
              <b> Sistem Informasi Layanan <br />Perencanaan </b>
            </h4>
            <p class="text-primary">Bappeda Kota Pagaralam</p>
            <div class="row">
              <div class="col-md-6 mx-auto text-center d-grid">
                <a
                  href="http://silaper.bappedapagaralam.org"
                  target="_blank"
                  class="btn btn-primary btn-block rounded-pill"
                >
                  Selengkapnya
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <img
              src="@/assets/images/slide_2.png"
              style="width: 50%; position: absolute"
              class="ml-1"
              alt=""
            />
            <img
              src="@/assets/images/Rectangle 686.png"
              style="width: 100%"
              alt=""
            />
          </div>
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .mobile {
    margin-top: 63px;
  }
}
</style>