<template>
  <div>
    <b-navbar
      toggleable="lg"
      class="navbar navbar-expand-lg bg-light fixed-top p-2 px-4"
      type="light"
    >
      <b-navbar-brand to="/" class="">
        <img src="@/assets/icon/logo.png" class="logo main-logo" />
        <img src="@/assets/icon/bappeda.png" class="logo bappeda" />
      </b-navbar-brand>
      <b-navbar-toggle
        class="text-center"
        target="nav-collapse"
      ></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="m-auto">
          <b-nav-item to="/">
            <span class="text-primary fw-bold">Beranda</span>
          </b-nav-item>
          <b-nav-item to="/profile">
            <span class="text-primary fw-bold">Tugas Fungsi</span>
          </b-nav-item>
          <b-nav-item to="/struktur-organisasi">
            <span class="text-primary fw-bold">Struktur Organisasi</span>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
      <b-navbar-brand @click.prevent="goTo()" class="">
        <img src="@/assets/icon/silaper.png" class="logo silaper" />
      </b-navbar-brand>
    </b-navbar>
    <a
      href="http://silaper.bappedapagaralam.org"
      target="_blank"
      ref="silaper"
      hidden
    ></a>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      token: null,
    };
  },
  methods: {
    goTo() {
      this.$refs.silaper.click();
    },
    logout() {
      localStorage.clear();
      this.$router.replace("/");
    },
  },
  computed: {
    checkToken() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
  watch: {
    checkToken(val) {
      this.token = val;
      // console.log(this.token);
    },
  },
  mounted() {
    this.token = this.$store.getters["auth/isLoggedIn"];
  },
};
</script>

<style>
.logo {
  height: 40px;
  position: absolute;
  top: 7px;
}
.main-logo {
  top: 10px;
  left: 15px;
}
.bappeda {
  left: 55px;
}
.silaper {
  right: 15px;
}

.text-primary {
  color: #0a468f !important;
}
.text-primary:hover {
  color: #3c6ca7 !important;
}
</style>