<template>
  <!-- menu utama -->
  <div class="container menu">
    <div class="row mb-5">
      <div class="col-lg-4 mx-auto text-center">
        <br />
        <button
          class="btn bg-original text-uppercase fw-bold rounded-pill"
          @click="$router.push('/report')"
        >
          Buat Pengaduan Masyarakat
        </button>
        <br />
      </div>
    </div>
    <div class="card p-3 bg-original rounded mb-4 shadow">
      <div class="row">
        <div class="col-md">
          <a target="_blank" href="http://www.pagaralamkota.go.id/">
            <img src="@/assets/icon/pemkot.png" class="linked-icon" />
          </a>
        </div>
        <div class="col-md">
          <a target="_blank" href="http://www.lapor.go.id/">
            <img src="@/assets/icon/lapor.png" class="linked-icon" />
          </a>
        </div>
        <div class="col-md">
          <a target="_blank" href="http://lpse.pagaralamkota.go.id/eproc4">
            <img src="@/assets/icon/lpse.png" class="linked-icon" />
          </a>
        </div>
        <div class="col-md">
          <a target="_blank" href="https://sipd.kemendagri.go.id/">
            <img src="@/assets/icon/sipd.png" class="linked-icon" />
          </a>
        </div>
      </div>
    </div>
    <br />
    <h4 class="text-center font-weight-bold text-primary">Gallery</h4>
    <p class="text-center">Dokumentasi kegiatan Bappeda Kota Pagar Alam</p>
    <hr />
    <div class="row row-cols-1 row-cols-md-3 g-4">
      <template v-for="item in gallery">
        <div :key="item.id" class="col">
          <div @click="view(item)">
            <div class="card shadow rounded m-2">
              <img
                :src="item.file"
                class="card-img-top"
                style="height: 200px; object-fit: cover"
                alt="..."
              />
              <div class="card-body">
                <h5 class="card-title text-primary">{{ item.title }}</h5>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="col-lg-4 mx-auto text-center">
      <br />
      <button
        class="btn bg-original text-uppercase fw-bold rounded-pill"
        @click="$router.push('/gallery')"
      >
        Lihat lainnya
      </button>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gallery: [],
      zoom: false,
      photo_zoom: "",
      page: 1,
      last_page: 1,
      preview: "",
      total: 0,
      form: {},
      params: {
        sorttype: "desc",
        sortby: "id",
        row: 6,
        page: 1,
      },
      createModal: false,
      isUpdate: false,
      isShow: false,
    };
  },
  methods: {
    zoomIn(item) {
      this.zoom = true;
      this.photo_zoom = item;
    },
    getData() {
      this.$store
        .dispatch("data/getGallery", this.params)
        .then((resp) => {
          this.gallery = resp.data.data;
          console.log(this.gallery);
          this.total = resp.data.total;
          console.log(this.total);
          this.last_page = resp.data.last_page;
        })
        .catch((e) => {
          this.$toast.error(e);
        });
    },
    view(item) {
      this.$router.push({ name: "Gallery Detail", params: { data: item } });
    },
    pagination(page) {
      this.page = page;
      this.params.page = page;
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.bg-original {
  background: linear-gradient(205.11deg, #3c6ca7 15.96%, #5a98f2 97.98%);
  color: white;
}
.bg-original:hover {
  box-shadow: 0px 4px 10px 0px #888;
}
.linked-icon {
  position: relative;
  height: 70px;
}

.col {
  text-align: center !important;
}

.menu {
  padding-top: 40px;
}

h3 {
  padding-top: 20px;
  color: #4d504e;
}

p {
  color: #94a2b3;
}
</style>
