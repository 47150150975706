<template>
  <div class="footer">
    <div class="footer-detail">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-6 mb-3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15919.623315757795!2d103.2027588!3d-4.0396379!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5eb069bea6e58a17!2sBappeda%20Kota%20pagaralam!5e0!3m2!1sid!2sid!4v1634891220918!5m2!1sid!2sid"
              width="100%"
              height="250px"
              class="rounded-3"
              style="border: 0"
              allowfullscreen="true"
              loading="lazy"
            ></iframe>
          </div>
          <div class="col-lg-6">
            <div class="container-fluid">
              <h3 class="fw-bold mb-2">Bappeda Kota Pagaralam</h3>
              <br />
              <span>(0730) 623500</span><br />
              <span>bappedapagaralam@gmail.com</span><br /><br />

              <span><strong>Alamat :</strong></span
              ><br />
              <span
                >Jl. Laskar Wanita Mentarjo Gunung gare, Pagar Wangi, Dempo
                Utara, Kota Pagar Alam, Sumatera Selatan 31518</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      markers: [
        {
          position: {
            lat: -4.038803129481503,
            lng: 103.20254422329255,
          },
        },
      ],
    };
  },
  methods: {
    resizeIframe(obj) {
      obj.style.height =
        obj.contentWindow.document.documentElement.scrollHeight + "px";
    },
  },
};
</script>

<style>
.footer {
  background: linear-gradient(148.12deg, #3d6da8 19.24%, #5a98f2 64.32%);
  margin-top: 30px;
  padding-top: 30px;
  color: #ffff;
  border-radius: 100px 100px 0px 0px;
}

.img {
  padding: 20px;
}
</style> 